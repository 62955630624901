<template>
  <div id="genotype-list">
    <Loader v-if="isLoading" />
    <div v-show="!isLoading">
      <div style="text-align: right !important; display: flex">
        <vs-button
          type="border"
          icon-pack="feather"
          icon="icon-plus-circle"
          @click="onCreateClick"
          >{{ $l.get("dictionary.create-button") }}</vs-button
        >
        <vs-button
          type="border"
          icon="cached"
          style="margin-left: 5px"
          @click="onProcessAllClick"
          >{{ $l.get("dictionary.process-all") }}</vs-button
        >
      </div>
      <vs-table
        :sst="true"
        @search="handleSearch"
        @sort="handleSort"
        search
        v-if="data"
        :data="data.genotypesPage.results"
      >
        <template slot="header">
          <h3>{{ $l.get("genotype-list.table-header") }}</h3>
        </template>
        <template slot="thead">
          <vs-th sort-key="snp__rsid">{{
            $l.get("genotype-list.table-snp")
          }}</vs-th>
          <vs-th sort-key="alleles">{{
            $l.get("genotype-list.table-alleles")
          }}</vs-th>
          <vs-th>{{ $l.get("genotype-list.table-actions") }}</vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>{{ tr.snp.rsid }}</vs-td>
            <vs-td>{{ tr.alleles }}</vs-td>
            <vs-td>
              <vs-button
                color="primary"
                type="border"
                icon="edit"
                @click="onGenotypeEditClick(tr)"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div style="margin-top: 20px; text-align: center">
        <vs-pagination
          :total="Math.ceil(data.genotypesPage.count / 25)"
          v-model="page"
          v-if="data"
        ></vs-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import SelectUpdateStatisticsInteractor from "@/business/genotype-list/select-update-statistics";
import SelectGenotypeEditInteractor from "@/business/genotype-list/select-genotype-edit";
import SelectCreateInteractor from "@/business/genotype-list/select-create";
import InitGenotypeListInteractor from "@/business/genotype-list/init-genotype-list";
import ChangeFilterInteractor from "@/business/genotype-list/change-filter";
import GenotypeListScreenController from "@/adapters/controllers/screen-genotype-list";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "genotype-list",
  components: { Loader },
  data() {
    return {
      controller: null,
      interactors: {
        selectUpdateStatistics: null,
        selecpdattatistics: null,
        selectGenotypeEdit: null,
        selectCreate: null,
        initGenotypeList: null,
        changeFilter: null
      },
      data: null,
      isLoading: false,
      page: 1,
      search: "",
      sort: "",
      searchTimer: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(GenotypeListScreenController);

    //{ INTERACTORS
    this.interactors.changeFilter = this.$injector.get(ChangeFilterInteractor);
    this.interactors.initGenotypeList = this.$injector.get(
      InitGenotypeListInteractor
    );
    this.interactors.selectCreate = this.$injector.get(SelectCreateInteractor);
    this.interactors.selectGenotypeEdit = this.$injector.get(
      SelectGenotypeEditInteractor
    );
    this.interactors.selectUpdateStatistics = this.$injector.get(
      SelectUpdateStatisticsInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initGenotypeList.handle();
  },
  watch: {
    page: function() {
      this.interactors.changeFilter.handle(this.page, this.search, this.sort);
    }
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    handleSearch(searching) {
      this.search = searching;
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      const _ = this;
      this.searchTimer = setTimeout(() => {
        _.searchTimer = null;
        if (_.page != 1) {
          _.page = 1; // watch force update
        } else {
          _.interactors.changeFilter.handle(_.page, _.search, _.sort);
        }
        //
      }, 500);
    },
    handleSort(key, active) {
      if (key) {
        if (active) {
          const prefix = active == "desc" ? "-" : "";
          this.sort = `${prefix}${key}`;
        } else {
          this.sort = "";
        }
        this.interactors.changeFilter.handle(this.page, this.search, this.sort);
      }
    },
    onGenotypeEditClick(data) {
      this.interactors.selectGenotypeEdit.handle(data);
    },
    onCreateClick() {
      this.interactors.selectCreate.handle();
    },
    onProcessAllClick() {
      let pks = [];
      for (let genotype of this.data.genotypesPage.results) {
        pks.push(genotype.id);
      }
      this.interactors.selectUpdateStatistics.handle(pks);
    }
  }
};
</script>

<style lang="scss"></style>
